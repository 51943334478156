/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiCheckLine, RiUserSettingsLine, RiFileTextLine, RiTimeLine, RiQuestionLine } from "react-icons/ri"

const PerformanceReviewsGuidePage = () => {
  const performanceReviewTemplates = [
    {
      title: "360-Degree Feedback",
      description: "Comprehensive evaluation from multiple stakeholders including managers, peers, and direct reports.",
      link: "/resources/templates/360-feedback-template-download"
    },
    {
      title: "Standard Performance Review",
      description: "Traditional evaluation focusing on performance against goals, competencies, and development areas.",
      link: "/resources/templates/standard-review-template-download"
    },
    {
      title: "Self-Assessment",
      description: "Employee evaluation of their own performance, achievements, and development areas.",
      link: "/resources/templates/self-assessment-template-download"
    }
  ];

  return (
    <Layout>
      <SEO 
        title="Performance Review Guide 2025 | Effective Evaluation Methods & Templates"
        description="Complete guide to conducting effective performance reviews, including templates, best practices, conversation examples, and top mistakes to avoid."
        keywords={[
          "performance review best practices",
          "employee evaluation guide",
          "performance review templates",
          "effective feedback techniques",
          "performance conversations",
          "SMART goals for reviews",
          "performance review examples",
          "360-degree feedback",
          "employee assessment methods",
          "performance management system"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              The Complete Guide to Performance Reviews
            </h1>
            
            <p sx={{
              fontSize: '1.1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              Learn how to conduct effective performance reviews that motivate employees, 
              improve performance, and align individual goals with organizational objectives.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
              <a href="#templates" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                }
              }}>
                Get Templates <RiFileTextLine />
              </a>
              
              <Link to="/tools/performance-review-builder" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                border: '2px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                }
              }}>
                Try Our Review Builder <RiUserSettingsLine />
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        bg: 'white',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          gap: 5
        }}>
          {/* Left Column - Main Content */}
          <div>
            {/* Introduction */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Why Performance Reviews Matter
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                Effective performance reviews are critical for organizational success, employee development, and team alignment. 
                When done well, they provide clear feedback, establish expectations, identify development opportunities, and 
                recognize achievements.
              </p>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                Unfortunately, many organizations struggle to conduct meaningful performance reviews, often relying on outdated 
                methods that create anxiety rather than motivation. This guide will help you transform your performance review 
                process into a valuable tool for growth and improvement.
              </p>
            </div>
            
            {/* Best Practices */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Performance Review Best Practices
              </h2>
              
              <div sx={{
                mb: 4
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'text',
                  mb: 2
                }}>
                  Prepare Thoroughly
                </h3>
                
                <ul sx={{
                  paddingLeft: '1.5rem',
                  mb: 3
                }}>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Collect comprehensive data:</strong> Gather information about the employee's performance from multiple sources, including key metrics, project outcomes, peer feedback, and self-assessments.
                  </li>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Review previous evaluations:</strong> Understand progress made since the last review and follow up on prior development goals.
                  </li>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Schedule adequate time:</strong> Allow sufficient time for meaningful discussion without interruptions.
                  </li>
                </ul>
              </div>
              
              <div sx={{
                mb: 4
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'text',
                  mb: 2
                }}>
                  Structure the Conversation
                </h3>
                
                <ul sx={{
                  paddingLeft: '1.5rem',
                  mb: 3
                }}>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Start with achievements:</strong> Begin by acknowledging successes to set a positive tone.
                  </li>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Be specific:</strong> Provide concrete examples rather than general statements.
                  </li>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Focus on behaviors:</strong> Discuss observable behaviors rather than personality traits.
                  </li>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Balance feedback:</strong> Cover both strengths and development areas.
                  </li>
                </ul>
              </div>
              
              <div sx={{
                mb: 4
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  color: 'text',
                  mb: 2
                }}>
                  Create Actionable Development Plans
                </h3>
                
                <ul sx={{
                  paddingLeft: '1.5rem',
                  mb: 3
                }}>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Set SMART goals:</strong> Establish Specific, Measurable, Achievable, Relevant, and Time-bound objectives.
                  </li>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Identify resources:</strong> Determine what support, training, or mentoring the employee needs.
                  </li>
                  <li sx={{
                    fontSize: '1rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 2
                  }}>
                    <strong>Document clearly:</strong> Create a written development plan with specific actions and timelines.
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Performance Review Templates */}
            <div id="templates" sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Performance Review Templates
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Use these customizable templates to structure your performance reviews:
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 3,
                mb: 4
              }}>
                {performanceReviewTemplates.map((template, idx) => (
                  <div key={`template-${template.title}`} sx={{
                    bg: 'backgroundMuted',
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
                    }
                  }}>
                    <h3 sx={{
                      fontSize: '1.25rem',
                      fontWeight: 600,
                      color: 'text',
                      mb: 2
                    }}>
                      {template.title}
                    </h3>
                    
                    <p sx={{
                      fontSize: '0.95rem',
                      color: 'text',
                      lineHeight: 1.5,
                      mb: 2
                    }}>
                      {template.description}
                    </p>
                    
                    <Link to="/contact" sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      color: 'primary',
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}>
                      Download Template <RiArrowRightSLine sx={{ ml: 1 }} />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Common Pitfalls */}
            <div sx={{ mb: 2 }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Common Pitfalls to Avoid
              </h2>
              
              <ul sx={{
                paddingLeft: '1.5rem',
                mb: 3
              }}>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Recency bias:</strong> Focusing too much on recent events rather than the entire performance period.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Lack of specificity:</strong> Providing vague feedback that doesn't help the employee understand what to continue or change.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Inconsistent standards:</strong> Applying different standards to different employees.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>One-way communication:</strong> Dominating the conversation instead of encouraging employee input.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>No follow-up:</strong> Failing to revisit development plans and provide ongoing feedback between formal reviews.
                </li>
              </ul>
            </div>
          </div>
          
          {/* Right Column - Sidebar */}
          <div>
            {/* Related Tools */}
            <div sx={{
              bg: 'backgroundMuted',
              p: 3,
              borderRadius: '8px',
              mb: 4,
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Related Tools
              </h3>
              
              <div sx={{
                mb: 3,
                pb: 3,
                borderBottom: '1px solid',
                borderColor: 'rgba(0,0,0,0.05)'
              }}>
                <Link to="/tools/performance-review-builder" sx={{
                  textDecoration: 'none'
                }}>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Performance Review Builder
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    opacity: 0.8,
                    mb: 2
                  }}>
                    Create customized performance review forms tailored to different roles and departments.
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}>
                    Try Tool <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              </div>
              
              <div sx={{
                mb: 3,
                pb: 3,
                borderBottom: '1px solid',
                borderColor: 'rgba(0,0,0,0.05)'
              }}>
                <Link to="/tools/goal-setting-template" sx={{
                  textDecoration: 'none'
                }}>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Goal Setting Template
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    opacity: 0.8,
                    mb: 2
                  }}>
                    Create SMART goals aligned with organizational objectives and individual development.
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}>
                    Try Tool <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              </div>
              
              <div>
                <Link to="/tools/feedback-templates" sx={{
                  textDecoration: 'none'
                }}>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Feedback Templates
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    opacity: 0.8,
                    mb: 2
                  }}>
                    Ready-to-use templates for different types of feedback situations.
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}>
                    Try Tool <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              </div>
            </div>
            
            {/* Expert Tips */}
            <div sx={{
              bg: 'backgroundMuted',
              p: 3,
              borderRadius: '8px',
              mb: 4,
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Expert Tips
              </h3>
              
              <div sx={{
                mb: 3
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Continuous Feedback
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Don't wait for annual reviews. Provide regular feedback throughout the year.
                </p>
              </div>
              
              <div sx={{
                mb: 3
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Focus on Growth
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Frame feedback as opportunities for development rather than criticism.
                </p>
              </div>
              
              <div>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Two-Way Dialogue
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Encourage employees to share their perspective and self-assessment.
                </p>
              </div>
            </div>
            
            {/* Need Help? */}
            <div sx={{
              bg: 'primary',
              color: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 6px rgba(0,0,0,0.1)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 2
              }}>
                Need Personalized Help?
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                opacity: 0.9,
                lineHeight: 1.5,
                mb: 3
              }}>
                Our HR consultants can help you design and implement an effective performance review process tailored to your organization.
              </p>
              
              <Link to="/contact" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)'
                }
              }}>
                Contact Us <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div sx={{
        bg: 'backgroundMuted',
        py: [4, 5],
        mt: -4,
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '960px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: '1.75rem',
            fontWeight: 700,
            textAlign: 'center',
            mb: 4,
            color: 'text'
          }}>
            Frequently Asked Questions
          </h2>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
          }}>
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                How often should we conduct performance reviews?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                While annual reviews are traditional, many organizations are moving toward more frequent check-ins. 
                Quarterly reviews provide more regular feedback opportunities while still maintaining formality. 
                Some organizations complement formal reviews with informal monthly check-ins to ensure continuous alignment.
              </p>
            </div>
            
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                How can we make performance reviews less stressful?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                Reduce stress by providing continuous feedback throughout the year, so formal reviews don't contain surprises. 
                Use a conversational approach rather than a "judgment" framework. Involve employees in the process through 
                self-assessment and goal-setting. Focus on development and growth opportunities rather than solely on evaluation.
              </p>
            </div>
            
            <div>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                How should we handle remote employee performance reviews?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                Remote performance reviews should focus on outcomes and results rather than observation of work habits. 
                Establish clear expectations and metrics at the beginning of the review period. Use video conferencing 
                for face-to-face interaction during the review conversation. Consider asynchronous elements like pre-review 
                questionnaires to accommodate different time zones.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div sx={{
        bg: 'white',
        py: [4, 5],
        textAlign: 'center',
        mt: -1
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: '1.75rem',
            fontWeight: 700,
            color: 'text',
            mb: 3
          }}>
            Ready to Transform Your Performance Review Process?
          </h2>
          
          <p sx={{
            fontSize: '1.1rem',
            color: 'text',
            lineHeight: 1.5,
            mb: 4
          }}>
            Start implementing these best practices today to create a more effective, 
            development-focused performance review system in your organization.
          </p>
          
          <div sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            <Link to="/tools/performance-review-builder" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }
            }}>
              Try Our Performance Review Builder <RiUserSettingsLine />
            </Link>
            
            <Link to="/guides" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'backgroundMuted',
              color: 'text',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)'
              }
            }}>
              Explore More HR Guides <RiArrowRightSLine />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PerformanceReviewsGuidePage 